/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { getSnapshot } from 'mobx-state-tree';
import CommonUtils from '../../common/utils/CommonUtils';
import * as constants from '../../common/constants';
import PopularChannelsSliderContanier from '../../common/components/PopularChannelsSliderContanier';
import ChannelExamsContainer from '../authhome/ChannelExamsContainer';
import env from '../../common/env';

import Onboarding from './Onboarding';

/**
 * @author Moahmed yasar arabath M
 */
export default class OnboardingPlugin {
  constructor(context) {
    this.context = context;
    this.language = this.context.props.language;
    // To maintain local state of Popular channels card not from mobx store
    this.addCourseChannelCards = null;
    this.onboardStudyPlan = {
      id: constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN,
      title: this.language.getMessage('onboard.studyPlan'),
      imageUrl: `${env.PLUS_URL}/auth-home/media/images/etextReadBook.png`,
      description: this.language.getMessage('onboard.addCourseAnother'),
      links: [
        {
          id: constants.ONBOARDING_LINKS.PURCHASE_ETEXT_BOOK,
          type: 'buttonAsLink',
          text: this.language.getMessage('onboard.purchase'),
          launchURL: `${env.BOOKSHELF_CONFIG.purchase}`,
          target: '_self',
          disabled: false
        },
        {
          id: constants.ONBOARDING_LINKS.PURCHASE_ETEXT_WITHOUT_BOOK,
          type: 'buttonAsLink',
          text: this.language.getMessage('onboard.addCourseWithoutEtext'),
          isInternalLink: true,
          action: 'next',
          disabled: false,
          disabledToolTipText: this.language.getMessage('onboard.disableTooltipText')
        }
      ],
      action: [
        {
          type: 'button',
          text: this.language.getMessage('onboard.mayBeLater'),
          isInternalLink: true,
          action: 'close',
          className: constants.PENDO_IDENTIFICATION_CLASS.ONBOARDING_MAYBE_LATER
        }
      ]
    };
    this.onboardAddcourse = {
      id: constants.ONBOARDING_SLIDES.ADD_COURSE,
      title: this.language.getMessage('onboard.aboutCourse'),
      description: this.language.getMessage('onboard.studyMaterials'),
      action: [
        {
          type: 'button',
          text: this.language.getMessage('onboard.mayBeLater'),
          isInternalLink: true,
          action: 'close',
          className: constants.PENDO_IDENTIFICATION_CLASS.ONBOARDING_MAYBE_LATER
        }
      ]
    };
  }

  updateChannelData = (slide, activeChannelCards) => {
    const renderSlide = slide;
    if (slide.id === constants.ONBOARDING_SLIDES.ADD_COURSE) { // Updating the exam prep with new channel id
      renderSlide.component = this.getPopularChannelSlider(activeChannelCards);
    }

    return renderSlide;
  }

  handleOnBoardPopularChannelCardClick = (index) => {
    const { herobanner } = this.context.props;
    let activeChannelCards = CommonUtils.getActiveChannelCards(getSnapshot(herobanner.popularChannels?.channelCards));
    if (this.context.commonPlugin.isAHNewUi()) {
      activeChannelCards = this.context.commonPlugin.getSortedPopularChannels(
        this.getChannelCards(),
        this.context.state.addCourseFilter
      );
    }
    const selectedChannelCard = activeChannelCards[index];
    let updatedChannelCard = [];
    if (selectedChannelCard) {
      updatedChannelCard = [...activeChannelCards];
      if (this.context.commonPlugin.isAHNewUi()) {
        updatedChannelCard[index] = {
          ...selectedChannelCard,
          active: false,
          recommended: false,
          isFiltered: false
        };
      } else {
        updatedChannelCard.splice(index, 1);
      }
    }
    this.context.coursePlugin.handlePopularChannelCardClick(null, selectedChannelCard.channelId);
    this.context.setState((state) => {
      let { onboardSlideData } = state;
      onboardSlideData = onboardSlideData.map((slide) => {
        const renderSlide = this.updateChannelData(
          slide,
          updatedChannelCard
        );

        if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) {
          renderSlide.links = this.getOnboardLinks();
        }

        return renderSlide;
      });

      return { onboardSlideData };
    });
    if (selectedChannelCard) {
      // eslint-disable-next-line no-empty
      if (selectedChannelCard.showExams) {
        this.context.setState((state) => {
          let { onboardSlideData } = state;
          onboardSlideData = onboardSlideData.map((slide) => {
            const renderSlide = slide;
            if (slide.id === constants.ONBOARDING_SLIDES.EXAM_PREP) { // Updating the exam prep with new channel id
              renderSlide.component = this.getChannelExam(
                selectedChannelCard.channelId,
                0
              );
            }

            if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
              renderSlide.title = this.language.getMessage('onboard.studyPlan');
              renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
            }

            return renderSlide;
          });

          return { onboardSlideData, onboardGotoSlide: 3 };
        });
      } else {
        this.context.setState((state) => {
          let { onboardSlideData } = state;
          onboardSlideData = onboardSlideData.map((slide) => {
            const renderSlide = this.updateChannelData(
              slide,
              updatedChannelCard
            );
            if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
              renderSlide.title = this.language.getMessage('onboard.courseAdded');
              renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
            }

            return renderSlide;
          });

          return { onboardSlideData, onboardGotoSlide: 0 };
        });
      }
    }
  }

  /**
   * This method will return the updated channels
   *
   * @param {*} channelCards
   * @returns
   */

  getChannelCards = (channelCards = []) => {
    const { herobanner } = this.context.props;
    let renderChannelCards = getSnapshot(herobanner.popularChannels?.channelCards);

    if (channelCards?.length > 0) {
      renderChannelCards = [...channelCards];
    } else if (this.addCourseChannelCards?.length > 0) {
      renderChannelCards = [...this.addCourseChannelCards];
    }

    return renderChannelCards;
  };

  getPopularChannelSlider = (channelCards) => {
    const { language } = this.context.props;
    if (channelCards && channelCards.length > 0) {
      this.addCourseChannelCards = channelCards;
    }

    if (this.context.commonPlugin.isAHNewUi()) {
      const popularChannelSliderProps = {
        // eslint-disable-next-line max-len
        channelCards: this.context.commonPlugin.getSortedPopularChannels(this.getChannelCards(channelCards), this.context.state.addCourseFilter),
        styleVaraint: constants.VARIANT_C,
        config: { ...this.context.commonPlugin.getPopularChannelsConfig() },
        filterList: this.context.commonPlugin.getPopularChannelsFilterData(
          this.getChannelCards(channelCards),
          this.context.state.addCourseFilter,
          true
        ),
        variant: this.context.commonPlugin.getVariant(),
        onPopularChannelCardClick: (index) => {
          this.handleOnBoardPopularChannelCardClick(index);
        },
        onPopularChannelFilterItemClick: (id) => {
          this.context.setState({ addCourseFilter: id }, () => {
            let { onboardSlideData } = this.context.state;
            onboardSlideData = onboardSlideData.map((slide) => {
              const renderSlide = this.updateChannelData(
                slide,
                null
              );
              return renderSlide;
            });
            this.context.setState({ onboardSlideData });
          });
        },
        gap: 8,
        locale: language.currentLanguage,
      };
      const popularChannelSlider = { Component: PopularChannelsSliderContanier, componentProps: popularChannelSliderProps };
      return popularChannelSlider;
    }

    const popularChannelSliderProps = {
      channelCards: [...channelCards],
      styleVaraint: constants.VARIANT_C,
      variant: this.context.commonPlugin.getVariant(),
      config: { ...this.context.commonPlugin.getPopularChannelsConfig() },
      onPopularChannelCardClick: (index) => {
        this.handleOnBoardPopularChannelCardClick(index);
      },
      gap: 8
    };

    const popularChannelSlider = { Component: PopularChannelsSliderContanier, componentProps: popularChannelSliderProps };
    return popularChannelSlider;
  }

  getChannelExam = (channelId, onboardGotoSlide) => {
    const { user, language, herobanner } = this.context.props;

    const channelExamsContainerProps = {
      userDetail: getSnapshot(user),
      env: env.ENVIRONMENT,
      locale: language.currentLanguage,
      channelId,
      onBoarding: true,
      onMaybeLater: () => {
        this.handleExamPrepMaybeLater(onboardGotoSlide);
      },
      onComplete: () => {
        this.context.setState({ onboardGotoSlide });
        herobanner.setChannelId(null);
        herobanner.setChannelId(channelId);
      },
      locationInApp: constants.EXAM_PREP_FLOW.ADD_COURSE_FLOW,
      newUI: this.context.commonPlugin.isAHNewUi()
    };

    return { Component: ChannelExamsContainer, componentProps: channelExamsContainerProps };
  }

  /**
   * Disables add course link and returns all onboarding links
   *
   * @returns
   */
  getOnboardLinks = () => {
    const disableAddCourse = !CommonUtils.hasAddableChannels();

    return [...this.onboardStudyPlan.links].map((link) => {
      if (link.id === constants.ONBOARDING_LINKS.PURCHASE_ETEXT_WITHOUT_BOOK) {
        return {
          ...link,
          disabled: disableAddCourse
        };
      }

      return { ...link };
    });
  };

  /**
   * Set onboard slide
   *
   *
   */
  modifySlideData = () => {
    const { user, herobanner } = this.context.props;
    const popularChannelsData = getSnapshot(herobanner).popularChannels;
    let activeChannelCards = CommonUtils.getActiveChannelCards(popularChannelsData.channelCards);
    const isBundleUser = CommonUtils.hasSubscription(getSnapshot(user), [constants.ACTIVE], [constants.BUNDLE]);

    if (this.context.commonPlugin.isAHNewUi()) {
      activeChannelCards = this.context.commonPlugin.getSortedPopularChannels(popularChannelsData.channelCards, this.context.state.addCourseFilter);
    }

    if (CommonUtils.isHEIUser()) {
      this.onboardStudyPlan.links = this.onboardStudyPlan.links.filter(
        link => link.id !== constants.ONBOARDING_LINKS.PURCHASE_ETEXT_BOOK
      );
    }

    // If there is no channel card then neeed to remove purchase without etextbook
    this.onboardStudyPlan.links = this.getOnboardLinks();

    if (isBundleUser) {
      this.onboardStudyPlan.links = this.onboardStudyPlan.links.map(
        (link) => {
          const renderLink = link;
          if (renderLink.id === constants.ONBOARDING_LINKS.PURCHASE_ETEXT_WITHOUT_BOOK) {
            renderLink.text = this.language.getMessage('onboard.addCourseWithEtextBundle');
          }

          return renderLink;
        }
      );

      if (this.onboardStudyPlan?.links?.length === 2) {
        this.onboardStudyPlan.links.reverse();
      }
    }

    this.addCourseSlideData = [
      {
        ...this.onboardStudyPlan,
        title: this.language.getMessage('onboard.addCourseAnother'),
        description: '  '
      },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(activeChannelCards) },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      }
    ];
  }

  /**
   * Show course on board
   *
   */
  showCourseOnBoard = () => {
    const { herobanner } = this.context.props;
    let activeChannelCards = CommonUtils.getActiveChannelCards(getSnapshot(herobanner.popularChannels?.channelCards));
    if (this.context.commonPlugin.isAHNewUi()) {
      activeChannelCards = this.context.commonPlugin.getSortedPopularChannels(getSnapshot(herobanner.popularChannels?.channelCards), this.context.state.addCourseFilter);
    }

    const addCourseSlideData = [
      {
        ...this.onboardStudyPlan,
        title: this.language.getMessage('onboard.addCourseAnother'),
        description: '  ',
        links: this.getOnboardLinks()
      },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(activeChannelCards) },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      }
    ];
    this.context.setState({
      openOnBoard: true,
      onboardSlideData: [...addCourseSlideData],
      onboardGotoSlide: 0
    });
  };

  handleExamPrepMaybeLater = (onboardGotoSlide) => {
    this.context.setState((state) => {
      let { onboardSlideData } = state;
      onboardSlideData = onboardSlideData.map((slide) => {
        const renderSlide = slide;
        if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
          renderSlide.title = this.language.getMessage('onboard.courseAdded');
          renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
        }

        return renderSlide;
      });

      return { onboardSlideData, onboardGotoSlide };
    });
  }

  /**
   * Handle popup onclose
   *
   */
  handleOnClose = () => {
    this.addCourseChannelCards = null;
    this.context.setState({ openOnBoard: false, onboardGotoSlide: 0, addCourseFilter: constants.CHANNEL_FILTER.ALL_CHANNELS  });
  };

  /**
   * Handles slide buttons click
   *
   * @param {*} ctaId
   * @param {*} slideIndex
   * @param {*} isInternalLink
   * @param {*} launchURL
   * @param {*} action
   */
  handleButtonClick = (ctaId, slideIndex, isInternalLink, launchURL, action) => {
    const { GOTO, CLOSE, NEXT } = constants.ONBORAD_ACTION;
    if (isInternalLink && !!action.action) {
      switch (action.action.toUpperCase()) {
        case GOTO:
          this.context.setState({ onboardGotoSlide: action.value });
          break;
        case CLOSE:
          this.handleOnClose();
          break;
        // eslint-disable-next-line no-lone-blocks
        case NEXT: {
          this.context.setState({ onboardGotoSlide: ((slideIndex - 1) + 1) });
        }
          break;
        default:
          break;
      }
    }
  }

  /**
   * Render Welcome onbaord flow
   *
   * @returns
   */
  renderOnboard = () => (this.context.state.onboardSlideData.length > 0 ? (
    <Onboarding
      openOnBoard={this.context.state.openOnBoard}
      onboardSlideData={this.context.state.onboardSlideData}
      gotoSlide={this.context.state.onboardGotoSlide}
      callbacks={{
        onButtonClick: this.handleButtonClick
      }}
    />
  ) : null)
}
