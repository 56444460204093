/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to product
 *
 * @file Product.js
 * @author sandeep and Arish Kumar K
 */

import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import { EventProvider } from '@aquila/core';

import Subscription from './Subscription';
import Configuration from './Configuration';
import env from '../../../common/env';
import PathUtils from '../../../common/utils/PathUtils';
import ProductUtils from '../../../common/utils/ProductUtils';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';
import canadaBookList from '../../../common/canadaBookList.json';

const DEFAULT_CONFIG = {
  allowNotesCopy: false,
  allowTocEdit: false,
  applicationId: 'ise',
  enableQuickView: false,
  showAudio: true,
  showAudioBeta: false,
  showBackNav: true,
  showBookmark: true,
  showDrawer: true,
  showDropMenu: false,
  showHeader: true,
  showLastVisitedPage: true,
  showPref: true,
  showResource: false,
  showSearch: true,
  showShareNotes: false,
  showStudy: true,
  tenantId: env.TENANT_ID_ETEXT,
  tenantKey: env.TENANT_KEY_ETEXT,
  showSocialComponent: false,
  showPrintPageNav: false,
  showABSearch: false,
  showCopy: false,
  showAudioReadAlong: false,
  showABNavigation: false,
  showABAudio: false,
  showStudyPanelChannelsWidget: false,
  enableUserLastLocation: false,
  showABNavIcons: false,
  showFlashcardViewInContent: false,
  showFooter: true,
  showStudyChannelNewVariation: false,
  updateLastLocation: true,
  showABChannelsIconPosition: false,
  showABAITutor: false,
  showAITutor: false,
  enableAIShowMore: false,
  isFirstTimeAITutorUser: null,
  enableChannelInSearch: false,
  enableAISleepMode: true,
  showAISleepModeAB: true,
  showAIChannelVideos: false,
  enableTopicsForChannel: false,
  isLDFlagsFetched: false,
  enableDigitalOrPrintPageNavigation: false,
  enableSummaryContinuation: false,
  enableFooterAudio: false,
  miniPlayerHeaderABTestFeature: 0,
  enableAIForGlossary: false,
  enableAIShortAnswer: false,
  enableProblemSolveFeature: false,
  showSearchChannelVideoChanges: false,
  enableTOCPrintPage: false,
  enableExplainQuizOptions: 1,
  AIChatPlaceHolderText: '',
  showChannelsABHeaderTitle: true,
  enableTopicInQuizFlow: '',
  AIStudyToolAutoOpenLimit: 0,
  aitutorWelcomeScreenCohorts: constants.CRUSH_YOUR_FINALS,
  aiTutorAutoOpenCommands: null,
  aiTutorAutoOpenCount: 0,
  miniPlayerPaywallTestFeature: 0,
  showSearchChannelVideoPlayer: true,
  showUserHistory: false,
  enableMathMLKeyboard: false,
  isBundleSubscriber: null,
  isChannelsSubscriber: null,
  enableBundleChannelMiniPlayer: false,
  ChannelsVideoScore: 10,
  promptShownCount: 0,
  starterPromptLimit: 0,
  hideChannelsPopout: false,
  showTranslate: false,
  translationLanguage: null,
  showRecommendedPrompts: false,
  showSourceReferencePage: false,
  AIEnableMarkdown: false,
  AIEnableImageContext: false,
  isSearchStudyAutoSelected: false,
  showSummary: true,
  showPractice: true,
  enableNewMathConfig: false,
  enableAISearch: false,
  isAIContentMediaIcon: false,
  aiStudyToolNameABTest: false,
  miniplayerRedirectionTest: 0,
  AIStudyToolReminder: 0,
  showChannelsSubscribersStickyBanner: true,
  AIEnableNewValidateAnswer: false,
  showAIKeywordAB: 0,
  miniPlayerIconABTest: 0
};
const DEFAULT_MLM_CONFIG = {
  showHighlights: false,
  showBookmark: false,
  showShareNote: false,
  allowNotesCopy: false,
  showDrawer: false,
  showHeader: false,
  showStudy: false,
  showSearch: false,
  showFooter: false,
  showPref: false,
  updateLastLocation: false,
  showABChannelsIconPosition: false
};
const DEFAULT_PDF_CONFIG = {
  mathmlOn: false,
  enableQuickView: false,
  showAudio: false,
  showAudioBeta: false,
  showStudy: true,
  applicationId: 'etext1',
  showSocialComponent: false,
  showABSearch: false,
  showCopy: false,
  showABChannelsIconPosition: false
};

/**
 * A mobx model for author
 *
 */
const Author = types.model(
  'Author',
  {
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    otherName: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for Secondary channels
 *
 */
export const secondaryChannels = types.model(
  'SecondaryChannels',
  {
    channel_name: types.maybeNull(types.string),
    channel_id: types.maybeNull(types.string)
  }
);

/**
 * A mobx model for product
 *
 */
const Product = types.model(
  'Product',
  {
    audioBook: types.optional(types.string, ''),
    authors: types.maybeNull(types.union(types.array(Author), types.string)),
    basePath: types.maybeNull(types.string),
    backLinkUrl: types.maybeNull(types.string),
    bookID: types.maybeNull(types.union(types.string, types.integer, types.number)),
    catalogUrl: types.maybeNull(types.string),
    configuration: types.optional(Configuration, {
      enableUserPrinting: false,
      studyTools: false,
      printOfferUrl: '',
      includeMathMLLib: false,
      headerFeatures: {
        hasPrintLink: false
      },
      generalFeatures: {
        hasLeftAccordion: false
      },
      ipadFeatures: {
        hotSpotColor: '',
        isUnderLineHotspot: false,
        regionHotSpotAlpha: '',
        underLineHotSpotColor: '',
        underLineHotSpotThickness: '',
        underLineHotSpotHoverColor: ''
      },
      availableBookLanguages: [{
        languageDirection: '',
        languageID: 1,
        languageNameKey: ''
      }]
    }),
    coverThumbnailUrl: types.maybeNull(types.string),
    format: types.maybeNull(types.string),
    id: types.string,
    indexId: types.maybe(types.string),
    mathmlOn: types.optional(types.boolean, false),
    mobileReady: types.optional(types.boolean, false),
    model: types.maybeNull(types.string),
    platform: types.maybeNull(types.string),
    role: types.optional(types.string, 'student'),
    read_along: types.optional(types.boolean, false),
    status: types.maybeNull(types.string),
    thumbnailUrl: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    version: types.maybeNull(types.string),
    subscription: types.optional(Subscription, {
      allowAccess: false,
      businessModelCode: '',
      entitlementTier: null,
      entitlementLevel: null,
      endDate: 0,
      startDate: 0,
      startDateUTC: null,
      status: null,
      type: '',
      subscriptionId: ''
    }),
    tocUrl: types.maybeNull(types.string),
    serversideProcessed: types.maybeNull(types.union(types.string, types.integer, types.boolean)),
    serversideEnabled: types.maybeNull(types.union(types.string, types.integer, types.boolean)),
    serverSideUuid: types.maybeNull(types.string),
    twoPageLayoutLeft: types.maybeNull(types.string),
    social_supported: types.maybeNull(types.boolean),
    legacyBookId: types.maybeNull(types.string),
    legacyProductId: types.maybeNull(types.string),
    channel_name: types.maybeNull(types.string),
    channel_id: types.maybeNull(types.string),
    channel_mapping_type: types.maybeNull(types.string),
    secondary_channels: types.maybeNull(types.array(secondaryChannels)),
    insitu_videos: types.maybeNull(types.boolean),
    isPPlusProduct: types.optional(types.boolean, false),
    isAITutorSupported: types.maybeNull(types.string),
    isAIChannelTopicsSupported: types.maybeNull(types.string)
  }
).views(self => ({
  getBasePath(isBasePathChange, serverSideEnabled) {
    if (self.format === constants.CONTENT_TYPE_CITE) {
      return `${env.CONTENT_HOST}/${env.CITE_BASE_PATH}/${self.basePath}`;
    }
    if (self.model === constants.CUSTOM_COLLECTIONS) {
      return `${env.CONTENT_HOST}/${env.COLLECTIONS_BASE_PATH}`;
    }
    if (self.format === constants.CONTENT_TYPE_PXE) {
      return `${env.CONTENT_HOST}/${env.PXE_BASE_PATH}/${self.basePath}`;
    }
    if (self.model === constants.EPUB_BRONTE) {
      return `${env.CONTENT_HOST}/${env.EPUB_BASE_PATH}/${self.basePath}/`;
    }
    if (self.model === constants.EPUB_BRONTE_STANDARD) {
      return `${env.CONTENT_HOST}/${env.EPUB_STD_BASE_PATH}/${self.basePath}/`;
    }
    if (self.model === constants.ETEXT_PDF && !serverSideEnabled) {
      const s3Env = (self.basePath).split('/')[3];
      return isBasePathChange ? `${env.CONTENT_HOST}/etext/pdfassets/${s3Env}/ebookassets/ebook${self.indexId}/ipadpdfs/` : `${env.CONTENT_HOST}/eplayer/ebookassets/${s3Env}/ebook${self.indexId}/`;
    }
    if (self.model === constants.ETEXT_BV_BRONTE) {
      return `${env.CONTENT_HOST}/${env.BRONTE_BASE_PATH}/${self.basePath}`;
    }

    return `${env.CONTENT_HOST}${self.basePath}/`;
  },
  getConfig(locale, bundleId, featureFlags = false, userPreferences = false, isIntergratedMLMLaunch = false, isLDFlagsFetched = false) {
    const { 
      scrubberFeature,
      studyGuide,
      enableSocial,
      enablePrintPageNavigation,
      showABSearch,
      enableCopy,
      enableAudioReadAlong,
      showABNavigation,
      showABAudio,
      enableUserLastLocation,
      showABNavIcons,
      showFlashcardViewInContent,
      enablePrintPageNavigationBronte,
      showPDFChapterFilter,
      showABChannelsIconPosition,
      showABAITutor,
      showAITutor,
      topicTimeOut,
      enableStreamingAPI,
      delayMessageTimerInSecs,
      enableFreeResponse,
      enableParagraph,
      enableAIShowMore,
      enableChannelInSearch,
      enableAISleepMode,
      showAISleepModeAB,
      showAIChannelVideos,
      isAIReviewUser,
      enableTopicsForChannel,
      enableDigitalOrPrintPageNavigation,
      enableSummaryContinuation,
      enableFooterAudio,
      miniPlayerHeaderABTestFeature,
      enableAIForGlossary,
      enableAIShortAnswer: listOfBookAIShortAnswer,
      enableProblemSolveFeature,
      showSearchChannelVideoChanges,
      enableTOCPrintPage,
      enableExplainQuizOptions,
      AIChatPlaceHolderText,
      showChannelsABHeaderTitle,
      enableTopicInQuizFlow,
      AIStudyToolAutoOpenLimit,
      aitutorWelcomeScreenCohorts,
      aiTutorAutoOpenCommands,
      miniPlayerPaywallTestFeature,
      showSearchChannelVideoPlayer,
      showUserHistory,
      enableMathMLKeyboard,
      enableBundleChannelMiniPlayer,
      ChannelsVideoScore,
      starterPromptLimit,
      hideChannelsPopout,
      showTranslate,
      showRecommendedPrompts,
      showSourceReferencePage,
      AIEnableMarkdown,
      AIEnableImageContext,
      isSearchStudyAutoSelected,
      AIBerlinTurnOffSummary,
      AIBerlinTurnOffPractice,
      enableAISearch,
      AIBotIconNextToContentImage,
      miniplayerRedirectionTest,
      AIStudyToolReminder,
      showAIKeywordAB,
      miniPlayerIconABTest
    } = featureFlags;

    const {
      isTextCopyEnabled,
      isFirstTimeAITutorUser,
      aiTutorAutoOpenCount,
      promptShownCount,
      translationLanguage,
      showChannelsSubscribersStickyBanner
    } = userPreferences;

    const isBundleSubscriber = isLDFlagsFetched ? CommonUtils.isBundleSubscriber() : null;
    const isChannelsSubscriber = isLDFlagsFetched ? CommonUtils.isChannelsSubscriber() : null;
    const primaryChannelId = this.getPrimaryChannelId();

    const isCITE = self.format === constants.CONTENT_TYPE_CITE;
    const isPXE = self.format === constants.CONTENT_TYPE_PXE;
    const isBronte = self.format === constants.CONTENT_TYPE_EPUB;
    const isBVBronte = ProductUtils.isBVBronteBook(self.format);
    const isSPdf = self.getServerSideStatus();
    // CITE type disable the DigitalOrPrintPageNavigation
    const isPrintPageNumberNavigation = isCITE ? false : enableDigitalOrPrintPageNavigation;
    const { currentLanguage } = Framework.getStoreRegistry().getStore('language');
    const isPdf = self.model === constants.ETEXT_PDF;
    const userLastLocationEnabled = ((isCITE || isPXE || isBronte) && enableUserLastLocation);
    const isshowFlashcardViewInContentEnabled = (isPXE || isBronte || isCITE || isBVBronte) ? showFlashcardViewInContent : false;
    const isPrintPageNavigationEnabled = isBronte ? enablePrintPageNavigationBronte : enablePrintPageNavigation;
    const isStudyChannelNewVariation = self.insitu_videos;
    const pdfChapterFilter = (isSPdf || isPdf) ? showPDFChapterFilter : true;
    const channelName = self.channel_name;
    const showStudyPanelChannelsWidget = primaryChannelId && typeof channelName === 'string' && channelName.trim().length > 0;
    const isShowAITutorEnabled = CommonUtils.isAITutorEnabled(showAITutor, isAIReviewUser, self.isAITutorSupported);
    const {
      enableNewMathConfig,
      aiStudyToolNameABTest,
      AIEnableNewValidateAnswer
    } = CommonUtils.commonAIConfig(featureFlags, self, { isAITutorEnabled: isShowAITutorEnabled });
    // TODO: need to add common function to get the bookId/PoductId
    const showSummary = Array.isArray(AIBerlinTurnOffSummary) ? !AIBerlinTurnOffSummary.includes((self.bookID || self.id)) : true;
    const showPractice = Array.isArray(AIBerlinTurnOffPractice) ? !AIBerlinTurnOffPractice.includes((self.bookID || self.id)) : true;
    // Enable AI channel videos only channel mapped title
    const enableAIChannelVideos = showAIChannelVideos && !!primaryChannelId;
    const isAIContentMediaIcon = AIEnableImageContext && CommonUtils.isTitleInList(
      AIBotIconNextToContentImage?.titles, { bookId: self.bookID, productId: self.id }
    );
    let isTopicInQuizFlow = false;
    if (enableTopicInQuizFlow) {
      const listOfBookIdsTopicInQuizFlow = enableTopicInQuizFlow.split(',');
      isTopicInQuizFlow = listOfBookIdsTopicInQuizFlow.includes(self.bookID) || listOfBookIdsTopicInQuizFlow.includes(self.id);
    }

    let isEnableTopicsForChannel = false;

    if (primaryChannelId) {
      if (self.isAIChannelTopicsSupported === constants.LIVE) {
        isEnableTopicsForChannel = enableTopicsForChannel;
      } else if (self.isAIChannelTopicsSupported === constants.REVIEW) {
        isEnableTopicsForChannel = isAIReviewUser;
      }
    }

    let enableAIShortAnswer = false;
    if (!CommonUtils.isEmpty(listOfBookAIShortAnswer) && listOfBookAIShortAnswer.titles && listOfBookAIShortAnswer.titles.length > 0) {
      enableAIShortAnswer = listOfBookAIShortAnswer.titles.some(({ bookId, productId }) => (self.bookID === bookId || self.id === productId));
    }

    if (isIntergratedMLMLaunch) {
      return {
        ...DEFAULT_CONFIG,
        ...DEFAULT_MLM_CONFIG
      };
    }
    if (bundleId) {
      return {
        ...DEFAULT_CONFIG,
        mathmlOn: self.mathmlOn || self.configuration.includeMathMLLib,
        showDrawer: true,
        showPref: true,
        showBackNav: false,
        showSearch: true,
        showDropMenu: false,
        showLastVisitedPage: false,
        showAudio: false,
        showBookmark: false,
        showResource: false,
        enableQuickView: false,
        showShareNotes: false,
        allowTocEdit: false,
        allowNotesCopy: false,
        locale: currentLanguage,
        showToc: false,
        showStudy: false,
        showNavigation: false,
        showHighlights: false,
        showSocialComponent: enableSocial && self.social_supported,
        showPrintPageNav: isPrintPageNavigationEnabled,
        showABSearch,
        showCopy: enableCopy && isTextCopyEnabled,
        showAudioReadAlong: ((enableAudioReadAlong) && (self.read_along) && (isPXE || isBronte || isCITE || isBVBronte)),
        showABNavigation,
        showABAudio,
        showStudyPanelChannelsWidget,
        enableUserLastLocation: userLastLocationEnabled,
        showABNavIcons,
        showFlashcardViewInContent: isshowFlashcardViewInContentEnabled,
        showStudyChannelNewVariation: isStudyChannelNewVariation,
        showPDFChapterFilter: pdfChapterFilter,
        showABChannelsIconPosition,
        showABAITutor,
        showAITutor: isShowAITutorEnabled,
        enableAIShowMore,
        isFirstTimeAITutorUser,
        topicTimeOut,
        enableStreamingAPI,
        delayMessageTimerInSecs,
        enableFreeResponse,
        enableParagraph,
        enableChannelInSearch,
        enableAISleepMode,
        showAISleepModeAB,
        showAIChannelVideos: enableAIChannelVideos,
        enableTopicsForChannel: isEnableTopicsForChannel,
        isLDFlagsFetched,
        enableDigitalOrPrintPageNavigation: isPrintPageNumberNavigation,
        enableSummaryContinuation,
        enableFooterAudio,
        miniPlayerHeaderABTestFeature,
        enableAIForGlossary,
        enableAIShortAnswer,
        enableProblemSolveFeature,
        showSearchChannelVideoChanges,
        enableTOCPrintPage,
        enableExplainQuizOptions,
        AIChatPlaceHolderText,
        showChannelsABHeaderTitle,
        enableTopicInQuizFlow: isTopicInQuizFlow,
        AIStudyToolAutoOpenLimit,
        aitutorWelcomeScreenCohorts,
        aiTutorAutoOpenCommands,
        aiTutorAutoOpenCount,
        miniPlayerPaywallTestFeature,
        showSearchChannelVideoPlayer,
        showUserHistory,
        enableMathMLKeyboard,
        isBundleSubscriber,
        isChannelsSubscriber,
        enableBundleChannelMiniPlayer,
        ChannelsVideoScore,
        promptShownCount,
        starterPromptLimit,
        hideChannelsPopout,
        showTranslate,
        translationLanguage,
        showRecommendedPrompts,
        showSourceReferencePage,
        AIEnableMarkdown,
        AIEnableImageContext,
        isSearchStudyAutoSelected,
        showSummary,
        showPractice,
        enableNewMathConfig,
        enableAISearch,
        isAIContentMediaIcon,
        aiStudyToolNameABTest,
        miniplayerRedirectionTest,
        AIStudyToolReminder,
        showChannelsSubscribersStickyBanner,
        AIEnableNewValidateAnswer,
        showAIKeywordAB,
        miniPlayerIconABTest
      };
    }
    if (self.format === constants.CONTENT_TYPE_PDF) {
      if (isPdf) {
        const isCanadianConfig = self.isCanadaBook(self.bookID);

        return {
          ...DEFAULT_CONFIG,
          ...DEFAULT_PDF_CONFIG,
          showAudio: self.isAudioBook(self.audioBook),
          showAudioBeta: self.isAudioBetaBook(self.audioBook),
          showBackNav: true,
          showBookmark: isCanadianConfig ? false : self.configuration.toolBarFeatures.hasBookMarkPageButton,
          showDrawer: self.configuration.generalFeatures.hasLeftAccordion,
          tenantKey: env.TENANT_KEY_ETEXT_PDF,
          clientApp: constants.PDF_CLIENT_APP,
          locale,
          showHighlights: !isCanadianConfig,
          showNavigation: scrubberFeature,
          showStudy: studyGuide && !isCanadianConfig,
          showSocialComponent: enableSocial && self.social_supported,
          showPrintPageNav: isPrintPageNavigationEnabled,
          showABSearch,
          showCopy: enableCopy && isTextCopyEnabled,
          showABNavigation,
          showABAudio,
          showABNavIcons,
          showStudyChannelNewVariation: isStudyChannelNewVariation,
          showPDFChapterFilter: pdfChapterFilter,
          showStudyPanelChannelsWidget,
          showABChannelsIconPosition,
          showABAITutor,
          showAITutor: isShowAITutorEnabled,
          enableAIShowMore,
          isFirstTimeAITutorUser,
          topicTimeOut,
          enableStreamingAPI,
          delayMessageTimerInSecs,
          enableFreeResponse,
          enableParagraph,
          enableChannelInSearch,
          enableAISleepMode,
          showAISleepModeAB,
          enableTopicsForChannel: isEnableTopicsForChannel,
          isLDFlagsFetched,
          enableDigitalOrPrintPageNavigation: isPrintPageNumberNavigation,
          enableSummaryContinuation,
          enableFooterAudio,
          miniPlayerHeaderABTestFeature,
          enableAIForGlossary,
          enableAIShortAnswer,
          enableProblemSolveFeature,
          showSearchChannelVideoChanges,
          enableTOCPrintPage,
          enableExplainQuizOptions,
          AIChatPlaceHolderText,
          showChannelsABHeaderTitle,
          enableTopicInQuizFlow: isTopicInQuizFlow,
          AIStudyToolAutoOpenLimit,
          aitutorWelcomeScreenCohorts,
          aiTutorAutoOpenCommands,
          aiTutorAutoOpenCount,
          miniPlayerPaywallTestFeature,
          showSearchChannelVideoPlayer,
          showUserHistory,
          enableMathMLKeyboard,
          isBundleSubscriber,
          isChannelsSubscriber,
          enableBundleChannelMiniPlayer,
          ChannelsVideoScore,
          promptShownCount,
          starterPromptLimit,
          hideChannelsPopout,
          showTranslate,
          translationLanguage,
          showRecommendedPrompts,
          showSourceReferencePage,
          AIEnableMarkdown,
          AIEnableImageContext,
          isSearchStudyAutoSelected,
          showSummary,
          showPractice,
          enableNewMathConfig,
          enableAISearch,
          isAIContentMediaIcon,
          aiStudyToolNameABTest,
          miniplayerRedirectionTest,
          AIStudyToolReminder,
          showChannelsSubscribersStickyBanner,
          AIEnableNewValidateAnswer,
          showAIKeywordAB,
          miniPlayerIconABTest
        };
      }
      if (self.model === constants.CUSTOM_COLLECTIONS) {
        return {
          ...DEFAULT_CONFIG,
          clientApp: constants.COLLECTION_CLIENT_APP,
          showStudy: studyGuide,
          showNavigation: scrubberFeature,
          showAudio: self.isAudioBook(self.audioBook),
          showAudioBeta: self.isAudioBetaBook(self.audioBook),
          showSocialComponent: enableSocial && self.social_supported,
          showPrintPageNav: isPrintPageNavigationEnabled,
          showABSearch,
          showCopy: enableCopy && isTextCopyEnabled,
          showABNavigation,
          showABAudio,
          showABNavIcons,
          showStudyChannelNewVariation: isStudyChannelNewVariation,
          showPDFChapterFilter: pdfChapterFilter,
          showStudyPanelChannelsWidget,
          showABChannelsIconPosition,
          showABAITutor,
          showAITutor: isShowAITutorEnabled,
          enableAIShowMore,
          isFirstTimeAITutorUser,
          topicTimeOut,
          enableStreamingAPI,
          delayMessageTimerInSecs,
          enableFreeResponse,
          enableParagraph,
          enableChannelInSearch,
          enableAISleepMode,
          showAISleepModeAB,
          showAIChannelVideos: enableAIChannelVideos,
          enableTopicsForChannel: isEnableTopicsForChannel,
          isLDFlagsFetched,
          enableDigitalOrPrintPageNavigation: isPrintPageNumberNavigation,
          enableSummaryContinuation,
          enableFooterAudio,
          miniPlayerHeaderABTestFeature,
          enableAIForGlossary,
          enableAIShortAnswer,
          enableProblemSolveFeature,
          showSearchChannelVideoChanges,
          enableTOCPrintPage,
          enableExplainQuizOptions,
          AIChatPlaceHolderText,
          showChannelsABHeaderTitle,
          enableTopicInQuizFlow: isTopicInQuizFlow,
          AIStudyToolAutoOpenLimit,
          aitutorWelcomeScreenCohorts,
          aiTutorAutoOpenCommands,
          aiTutorAutoOpenCount,
          miniPlayerPaywallTestFeature,
          showSearchChannelVideoPlayer,
          showUserHistory,
          enableMathMLKeyboard,
          isBundleSubscriber,
          isChannelsSubscriber,
          enableBundleChannelMiniPlayer,
          ChannelsVideoScore,
          promptShownCount,
          starterPromptLimit,
          hideChannelsPopout,
          showTranslate,
          translationLanguage,
          showRecommendedPrompts,
          showSourceReferencePage,
          AIEnableMarkdown,
          AIEnableImageContext,
          isSearchStudyAutoSelected,
          showSummary,
          showPractice,
          enableNewMathConfig,
          enableAISearch,
          isAIContentMediaIcon,
          aiStudyToolNameABTest,
          miniplayerRedirectionTest,
          AIStudyToolReminder,
          showChannelsSubscribersStickyBanner,
          AIEnableNewValidateAnswer,
          showAIKeywordAB,
          miniPlayerIconABTest
        };
      }
      return {
        ...DEFAULT_CONFIG,
        ...DEFAULT_PDF_CONFIG
      };
    }
    return {
      ...DEFAULT_CONFIG,
      mathmlOn: self.mathmlOn || self.configuration.includeMathMLLib,
      indexId: self.indexId,
      showBackNav: true,
      showStudy: studyGuide,
      locale: currentLanguage,
      showAudio: self.isAudioBook(self.audioBook),
      showAudioBeta: self.isAudioBetaBook(self.audioBook),
      showNavigation: scrubberFeature,
      showSocialComponent: enableSocial && self.social_supported,
      showPrintPageNav: isPrintPageNavigationEnabled,
      showABSearch,
      showCopy: enableCopy && isTextCopyEnabled,
      showAudioReadAlong: ((enableAudioReadAlong) && (self.read_along) && (isPXE || isBronte || isCITE || isBVBronte)),
      showABNavigation,
      showABAudio,
      showStudyPanelChannelsWidget,
      enableUserLastLocation: userLastLocationEnabled,
      showABNavIcons,
      showFlashcardViewInContent: isshowFlashcardViewInContentEnabled,
      showStudyChannelNewVariation: isStudyChannelNewVariation,
      showPDFChapterFilter: pdfChapterFilter,
      showABChannelsIconPosition,
      showABAITutor,
      showAITutor: isShowAITutorEnabled,
      enableAIShowMore,
      isFirstTimeAITutorUser,
      topicTimeOut,
      enableStreamingAPI,
      delayMessageTimerInSecs,
      enableFreeResponse,
      enableParagraph,
      enableChannelInSearch,
      enableAISleepMode,
      showAISleepModeAB,
      showAIChannelVideos: enableAIChannelVideos,
      enableTopicsForChannel: isEnableTopicsForChannel,
      isLDFlagsFetched,
      enableDigitalOrPrintPageNavigation: isPrintPageNumberNavigation,
      enableSummaryContinuation,
      enableFooterAudio,
      miniPlayerHeaderABTestFeature,
      enableAIForGlossary,
      enableAIShortAnswer,
      enableProblemSolveFeature,
      showSearchChannelVideoChanges,
      enableTOCPrintPage,
      enableExplainQuizOptions,
      AIChatPlaceHolderText,
      showChannelsABHeaderTitle,
      enableTopicInQuizFlow: isTopicInQuizFlow,
      AIStudyToolAutoOpenLimit,
      aitutorWelcomeScreenCohorts,
      aiTutorAutoOpenCommands,
      aiTutorAutoOpenCount,
      miniPlayerPaywallTestFeature,
      showSearchChannelVideoPlayer,
      showUserHistory,
      enableMathMLKeyboard,
      isBundleSubscriber,
      isChannelsSubscriber,
      enableBundleChannelMiniPlayer,
      ChannelsVideoScore,
      promptShownCount,
      starterPromptLimit,
      hideChannelsPopout,
      showTranslate,
      translationLanguage,
      showRecommendedPrompts,
      showSourceReferencePage,
      AIEnableMarkdown,
      AIEnableImageContext,
      isSearchStudyAutoSelected,
      showSummary,
      showPractice,
      enableNewMathConfig,
      enableAISearch,
      isAIContentMediaIcon,
      aiStudyToolNameABTest,
      miniplayerRedirectionTest,
      AIStudyToolReminder,
      showChannelsSubscribersStickyBanner,
      AIEnableNewValidateAnswer,
      showAIKeywordAB,
      miniPlayerIconABTest
    };
  },
  getCoverThumbnailUrl() {
    return self.coverThumbnailUrl && `${env.CONTENT_HOST}${self.coverThumbnailUrl}`;
  },
  getPrimaryChannelId() {
    let channelId = null;

    if (self.channel_id
      && CommonUtils.localStringCompare(
        self.channel_mapping_type, constants.CHANNEL_MAPPING_TYPES.EXACT
      )) {
      channelId = self.channel_id;
    }

    return channelId;
  },
  getBookInfo(printPageNumber) {
    const businessModelCode = self.getBusinessModelCode();
    const {
      legacyBookId: bookId,
      legacyProductId: productId,
      model
    } = self;
    const channelId = this.getPrimaryChannelId();
    const legacyIds = { bookId, productId };
    const legacyBookId = ProductUtils.getlegacyBookId(businessModelCode, legacyIds);
    return {
      author: self.authors,
      title: self.title,
      title2: self.title,
      thumbnailUrl: self.thumbnailUrl || constants.DEFAULT_BOOK_COVER,
      printPageNumber,
      isDoublePage: false,
      layoutDimensions: {
        rightStart: (self.twoPageLayoutLeft === constants.ODD)
      },
      preferenceObject: {
        sliderVal: '130',
        theme: 'White',
        zoom: 1.3
      },
      legacyBookId,
      productModel: model,
      channelId
    };
  },
  getBookFeatures() {
    return self.configuration.ipadFeatures;
  },
  /**
   * Method to get business model code
   *
   */
  getBusinessModelCode() {
    return self.subscription.businessModelCode;
  },
  getMenuData() {
    const {
      configuration,
      format,
      model,
      platform,
      subscription,
      thumbnailUrl
    } = self;

    return {
      configuration,
      format,
      model,
      platform,
      subscription,
      thumbnailUrl
    };
  },
  getPdfLocale() {
    return PathUtils.getPdfLanguageCode(self.configuration.availableBookLanguages[0].languageID);
  },
  isPxe() {
    return (self.model === 'ETEXT2_PXE') || false;
  },

  /**
   * Method to get the server side status check
   *
   */
  getServerSideStatus() {
    const isServerEnabled = PathUtils.getQueryParameterbyName('isServerEnabled');

    if (!isServerEnabled) {
      return self.serversideEnabled && self.serversideProcessed;
    }
    if (isServerEnabled === 'true') {
      return self.serversideProcessed;
    }

    return false;
  },

  /**
   * Method to check audio flag
   *
   * @param {string} audioFlag
   */
  isAudioBook(audioFlag) {
    return CommonUtils.isAudioBook(audioFlag);
  },

  /**
   * Method to check whether it is a candian book
   *
   * @param {string} bookId
   */
  isCanadaBook(bookId) {
    return canadaBookList.indexOf(bookId) >= 0;
  },

  /**
   * Method to check audio beta flag
   *
   * @param {string} audioFlag
   */
  isAudioBetaBook(audioFlag) {
    const {
      AUDIO_FLAG_ON_BETA
    } = constants;

    return audioFlag === AUDIO_FLAG_ON_BETA;
  },

  /**
   * Method to get product model
   *
   * @param {Object} menuData
   */
  getProductModel(menuData) {
    const requestProductModel = true;
    let productModel = ProductUtils.getProductDescription(menuData, requestProductModel);

    productModel = productModel.split(' ').join('').toLowerCase();

    return productModel;
  }
})).actions(self => ({
  fetch(id, bundleId) {
    if (self.id === id) {
      Framework.getEventManager().publish(constants.PRODUCT_DATA_FETCHED, {});
    } else {
      Framework.getEventManager().publish(constants.PRODUCT_SYNC_REQUESTED, { id, bundleId });
    }
  },
  /**
   * Does subscription check
   *
   * @param {string} title
   * @param {string} userId
   * @param {string} productId
   * @param {string} courseId
   * @param {string} courseName
   */
  doSubscriptionCheck(
    title,
    userId,
    productId = '',
    courseId = '',
    courseName = ''
  ) {
    if (!self.subscription.allowAccess) {
      CommonUtils.initializeGTM();
      const registrationUri = self.subscription.registrationUri || '';

      EventProvider.getEventManager().dispatch({
        event: 'Subscription Error',
        category: 'errorPage',
        action: 'Error',
        data: {
          title,
          userId,
          productId,
          courseId,
          courseName
        }
      });
      if (registrationUri.startsWith(env.ZEPPELIN_URL)) {
        // redirecting to console link for renew option
        window.location = `${env.CONSOLE_URL}`;
      } else {
        const errorCode = registrationUri ? 'SUBSCRIPTION_REGISTRATION' : 'SUBSCRIPTION_GENERIC';
        Framework.redirectToErrorPage(errorCode, 'common.PURCHASE', registrationUri);
      }
    }
  },
  /**
   * Sends error events to GA
   *
   * @param {Object} data
   * @param {string} productId
   * @param {string} courseId
   */
  sendErrorEvents(data, productId = '', courseId = '') {
    const {
      errorDetails: {
        error
      } = {}
    } = data;
    CommonUtils.initializeGTM();
    EventProvider.getEventManager().dispatch({
      event: 'Error',
      category: 'Error',
      action: 'Error logs',
      data: {
        errorMessage: error ? error.message : '',
        errorCode: error ? error.status : '',
        apiDetail: data.api,
        productId,
        courseId
      }
    });
  },
  /**
   * Updates data Layer based on response
   *
   */
  updateDataLayer() {
    const customFields = {
      businessModelCode: self.subscription.businessModelCode || '',
      productModelName: ProductUtils.getProductDescription(self.getMenuData()) || '',
      userId: Framework.getStoreRegistry().getStore('user').id
    };
    CommonUtils.dispatchGaEvent(null, null, null, null, customFields);
  },
  getContentType(isFromCourse) {
    let product = Framework.getStoreRegistry().getStore('product');
    if (isFromCourse) {
      product = Framework.getStoreRegistry().getStore('course').getProduct();
    }
    return product.format;
  },
  sendPageUnloadEvent(course, previousPageId, isFromCourse, durationSeconds, UnLoadUuid, UnLoadDt, UnloadUserLocalLoadDt, subscriptionData, currentPageId) {
    const courseInfo = isFromCourse ? course.products[0] : course;
    const {
      id, platform, model, title, role
    } = courseInfo;

    const contentType = self.getContentType(isFromCourse);
    const courseIdType = self.platform === constants.BUNDLED_PLATFORM ? constants.COURSE_TYPE_SMS : constants.COURSE_TYPE_REGISTRAR;
    const currrentPageUrn = window.location.href.split('pages/');
    const pageUserNavigatedFromUrn = previousPageId ? `${currrentPageUrn[0]}pages/${previousPageId}` : '';
    const pageUserNavigatedToUrn = currentPageId ? `${currrentPageUrn[0]}pages/${currentPageId}` : '';
    const pageId = previousPageId;
    const unLoadEvent = {
      event: constants.TELEMETRY_PAGE_UNLOAD,
      product_id: id,
      product_platform_code: platform,
      product_model_name: model || constants.PRODUCT_MODEL,
      business_model_code: self.subscription.businessModelCode,
      book_id: courseInfo.bookID || courseInfo.id,
      pearson_subscription_id: (subscriptionData && subscriptionData.subscriptionId) || null,
      pearson_subscription_tier_code: (subscriptionData && subscriptionData.entitlementLevel) || null,
      course_id: isFromCourse ? course.id : null,
      course_id_type: isFromCourse ? courseIdType : null,
      course_section_id: isFromCourse ? course.id : null, // By Telemetry team expectation course_id and  course_section_id will be the same
      course_section_id_type: isFromCourse ? courseIdType : null,
      course_section_title: courseInfo.title,
      content_id: pageId,
      content_id_type: env.PRODUCT.toLowerCase(),
      content_type: constants.CONTENT_TYPE, // It's static , Will change once will get data
      content_title: title,
      discipline_id: null,
      discipline_name: null,
      load_dt: UnLoadDt, // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      user_local_load_dt: UnloadUserLocalLoadDt, // "2021-05-04T17:38:48:195"
      time_on_task_uuid: UnLoadUuid,
      message_id: CommonUtils.getUUID(),
      transaction_dt: new Date().toISOString(), // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      transaction_local_dt: CommonUtils.getLocalIsoTime(), // "2021-05-04T17:38:48:195"
      unload_caused_by_code: courseInfo.role || role,
      duration_in_seconds: parseInt(durationSeconds, 10),
      page_urn: (window.location.href).split('?')[0],
      userAgent: window.navigator.userAgent
    };
    const request = {
      type: constants.USER_UNLOADS_CONTENT,
      pageId,
      userId: Framework.getStoreRegistry().getStore('user').id,
      isBundled: self.platform === constants.BUNDLED_PLATFORM,
      isFromCourse,
      businessModelCode: self.subscription.businessModelCode,
      playerModeCode: constants.PLAYER_MODE_CODE, // Static Will change once will get data, Ex: reading vs listening
      productId: id,
      pearsonSubscriptionId: (subscriptionData && subscriptionData.subscriptionId) || null,
      pearsonSubscriptionTierCode: (subscriptionData && subscriptionData.entitlementLevel) || null,
      loadUuid: UnLoadUuid,
      loadDt: UnLoadDt,
      userLocalDt: UnloadUserLocalLoadDt,
      bookId: courseInfo.bookID || courseInfo.id,
      durationInSeconds: parseInt(durationSeconds, 10),
      courseSectionTitle: isFromCourse && courseInfo.title,
      pageTitle: CommonUtils.getPageName(pageId),
      productModelName: model,
      productPlatformCode: platform,
      contentType,
      contentTitle: title,
      pageUserNavigatedFromUrn,
      pageUserNavigatedToUrn
    };
    if (isFromCourse) {
      request.course = Framework.getStoreRegistry().getStore('course');
    } else {
      request.product = getSnapshot(self);
    }
    /* AS per Naga instruction for testing load and Unloadevent, removed PDF restriction */
    Framework.getEventManager().publish(constants.AUTOBAHN_TIMEONTASK, request);
    CommonUtils.initializeGTM();
    EventProvider.getEventManager().dispatch(unLoadEvent);
  },
  sendPageLoadEvent(course, pageId, isFromCourse, loadUuid, loadDt, userLocalLoadDt, subscriptionData, previousPageId, navData) {
    const courseInfo = isFromCourse ? course.products[0] : course;
    const {
      id, platform, model, title
    } = courseInfo;
    const contentType = self.getContentType(isFromCourse);
    const businessModelCode = self.subscription.businessModelCode;
    const courseIdType = self.platform === constants.BUNDLED_PLATFORM ? constants.COURSE_TYPE_SMS : constants.COURSE_TYPE_REGISTRAR;
    const currrentPageUrn = window.location.href.split('pages/');
    const pageUserNavigatedFromUrn = previousPageId ? `${currrentPageUrn[0]}pages/${previousPageId}` : '';
    const pageUserNavigatedToUrn = `${currrentPageUrn[0]}pages/${pageId}`;

    const loadEvent = {
      event: constants.TELEMETRY_PAGE_LOAD,
      page_name: CommonUtils.getPageName(pageId),
      product_id: id,
      product_platform_code: platform,
      product_model_name: model || constants.PRODUCT_MODEL,
      business_model_code: businessModelCode,
      book_id: courseInfo.bookID || courseInfo.id,
      pearson_subscription_id: (subscriptionData && subscriptionData.subscriptionId) || null,
      pearson_subscription_tier_code: (subscriptionData && subscriptionData.entitlementLevel) || null,
      course_id: isFromCourse ? course.id : null,
      course_id_type: isFromCourse ? courseIdType : null,
      course_section_id: isFromCourse ? course.id : null, // By Telemetry team expectation course_id and  course_section_id will be the same
      course_section_id_type: isFromCourse ? courseIdType : null,
      course_section_title: courseInfo.title,
      content_id: pageId,
      content_id_type: env.PRODUCT.toLowerCase(),
      content_type: constants.CONTENT_TYPE, // It's static , Will change once will get the data
      content_title: title,
      discipline_id: null,
      discipline_name: null,
      load_dt: loadDt, // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      user_local_load_dt: userLocalLoadDt, // "2021-05-04T17:38:48:195"
      time_on_task_uuid: loadUuid,
      message_id: CommonUtils.getUUID(),
      transaction_dt: new Date().toISOString(), // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      transaction_local_dt: CommonUtils.getLocalIsoTime(), // "2021-05-04T17:38:48:195"
      capabilities_platform_code: null,
      app_component_version_id: null,
      offer_id: null,
      entitlement_id: (subscriptionData && subscriptionData.entitlementLevel) || null,
      page_urn: (window.location.href).split('?')[0],
      chapter_id: navData ? navData.chapterId : null,
      userAgent: window.navigator.userAgent
    };

    const request = {
      type: constants.USER_LOADS_CONTENT,
      pageId,
      userId: Framework.getStoreRegistry().getStore('user').id,
      isBundled: self.platform === constants.BUNDLED_PLATFORM,
      isFromCourse,
      businessModelCode: self.subscription.businessModelCode,
      playerModeCode: constants.PLAYER_MODE_CODE, // Static Will change once will get data, Ex: reading vs listening
      productId: id,
      pearsonSubscriptionId: (subscriptionData && subscriptionData.subscriptionId) || null,
      pearsonSubscriptionTierCode: (subscriptionData && subscriptionData.entitlementLevel) || null,
      loadUuid,
      loadDt,
      userLocalDt: userLocalLoadDt,
      bookId: courseInfo.bookID || courseInfo.id,
      courseSectionTitle: isFromCourse && courseInfo.title,
      pageTitle: CommonUtils.getPageName(pageId),
      productModelName: model,
      productPlatformCode: platform,
      contentType,
      contentTitle: title,
      pageUserNavigatedFromUrn,
      pageUserNavigatedToUrn
    };
    if (isFromCourse) {
      request.course = Framework.getStoreRegistry().getStore('course');
    } else {
      request.product = getSnapshot(self);
    }
    /* AS per Naga instruction for testing load and Unloadevent, removed PDF restriction */
    Framework.getEventManager().publish(constants.AUTOBAHN_TIMEONTASK, request);
    CommonUtils.initializeGTM();
    EventProvider.getEventManager().dispatch(loadEvent);
  },
  sendPageNavigation(courseInfo, pageId, isFromCourse, navData) {
    const { id } = courseInfo;
    const eventAction = navData && navData.action ? `${navData.action.charAt(0).toUpperCase()}${navData.action.slice(1)}` : null;
    const navigationEvent = {
      event: constants.NAVIGATION,
      event_action: eventAction,
      event_category: 'Content Navigation',
      category: 'Content Navigation',
      event_label: eventAction,
      event_value: pageId,
      transaction_local_dt: CommonUtils.getLocalIsoTime(),
      user_local_event_dt: CommonUtils.getLocalIsoTime(),
      message_id: CommonUtils.getUUID(),
      event_unix_timestamp: new Date().getTime(),
      chapter_id: navData ? navData.chapterId : null,
      content_id: pageId || null
    };
    if (isFromCourse) {
      navigationEvent.course_section_id = id;
    }
    CommonUtils.initializeGTM();
    EventProvider.getEventManager().dispatch(navigationEvent);
  },
  set(product) {
    applySnapshot(self, product);
    const userId = Framework.getStoreRegistry().getStore('user').id;

    self.doSubscriptionCheck(self.title, userId, self.id);
    self.updateDataLayer();
    Framework.getEventManager().publish(constants.PRODUCT_DATA_FETCHED, {});
  },
  /**
   * Function to load the PLA events
   * @param {string} pageId - page Id
   * @param {Boolean} isFromCourse - true if it is from courseware
   * @param {string} previousPageId - previous page Id
   * @param {number} durationSeconds - Duration between load and unload
   * @param {string} loadUuid - Unique ID
   * @param {string} loadDt - ISO String
   * @param {string} userLocalLoadDt - ISO String
   * @param {string} UnLoadUuid - Unique ID
   * @param {string} UnLoadDt - ISO String
   * @param {string} UnloadUserLocalLoadDt - ISO String
   */
  setPLAEvents(product, pageId, isFromCourse, previousPageId, durationSeconds, loadUuid, loadDt, userLocalLoadDt, UnLoadUuid, UnLoadDt, UnloadUserLocalLoadDt, loadType, navData) {
    const gpsSubscriptionOfProduct = self.getGpsSubscriptionOfProduct();
    const { status } = gpsSubscriptionOfProduct;
    const subscriptionData = (status === constants.ACTIVE) ? gpsSubscriptionOfProduct : null;
    if (previousPageId && previousPageId !== pageId && loadType === constants.UNLOAD) {
      self.sendPageUnloadEvent(product, previousPageId, isFromCourse, durationSeconds, UnLoadUuid, UnLoadDt, UnloadUserLocalLoadDt, subscriptionData, pageId);
      self.sendPageNavigation(product, pageId, isFromCourse, navData); // For Navigation tracking purpose
    }
    if (pageId && pageId !== previousPageId && loadType === constants.LOAD) {
      self.sendPageLoadEvent(product, pageId, isFromCourse, loadUuid, loadDt, userLocalLoadDt, subscriptionData, previousPageId, navData);
      CommonUtils.initTelemetryPageAction();
    }
  },
  /**
   * Get The product's GPS subscription
   *
   * @returns
   */
  getGpsSubscriptionOfProduct() {
    return self.subscription;
  },
  /**
   * set The product's matching GPS subscription based on the subscriptionId
   *
   */
  setGpsSubscriptionOfProduct() {
    const subscription = self.subscription;
    const subscriptionIdOfProduct = subscription && subscription.subscriptionId;
    const userStore = Framework.getStoreRegistry().getStore('user');
    const { startDate = null, entitlementTier = null, entitlementLevel = null, status = null } = CommonUtils.getMatchingGpsSubscriptionOfBook(
      subscriptionIdOfProduct,
      getSnapshot(userStore)
    );
    // eslint-disable-next-line no-param-reassign
    self.subscription = {
      ...subscription,
      startDateUTC: startDate,
      entitlementTier,
      entitlementLevel,
      status
    };
  },
  /**
   * Determines and sets whether or not it is a PPlus product and setting subscription of book.
   *
   */
  setIsPPlusProductAndGpsSubscriptionOfProduct() {
    self.setGpsSubscriptionOfProduct();
    const isPPlusProduct = CommonUtils.isPPlusUser(self.subscription, constants.EREADER);

    // eslint-disable-next-line no-param-reassign
    self.isPPlusProduct = isPPlusProduct;
  },
  /**
   * Returns whether or not it is a PPlus product
   *
   * @returns
   */
  getIsPPlusProduct() {
    return self.isPPlusProduct;
  }
}));
export default Product;
